<template>
  <div :style="styleObject">
    <button
      style="display: none"
      type="button"
      class="btn-show-item-detail-modal"
      data-bs-toggle="modal"
      data-bs-target="#modal_item_detail"
    ></button>
    <button
      style="display: none"
      type="button"
      class="btn-hide-item-detail-modal"
      data-bs-target="#modal_item_detail"
      data-bs-dismiss="modal"
    ></button>
    <div
      class="modal modal-full fade"
      id="modal_item_detail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal_item_detail"
      style="z-index: 10000"
    >
      <div class="btn-close-modal" @click="closeModel()">
        <div>&times;</div>
      </div>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body" style="padding: 0px;" v-on:scroll.passive='handleScroll($event)'>
            <div class="page-header align-items-start" v-if="isLoadDone">
              <div class="container">
                <div class="content">
                  <div class="item-img-container">
                    <div class="image-container">
                      <img :src=itemInfo.image alt="Food Image" class="food-image">
                    </div>
                    <div class="mt-3 pad-left-right" style="display: flex; align-items: flex-start;">
                      <div class="item-name" style="width: 70%; line-height: normal">
                        {{ itemInfo.item_name }}
                      </div>
                      <div style="width: 2%;"></div>
                      <div class="item-name" style="width: 28%;text-align: right;display: grid;justify-content: end;">
                        <span style="line-height: normal;font-size: 16px;margin-top: 3.5px;">S${{ isLogined ? (itemInfo.member_price ? (itemInfo.member_price * itemInfo.quantity).toFixed(2) : "0.00") : (itemInfo.price_default ? (itemInfo.price_default * itemInfo.quantity).toFixed(2) : "0.00") }}</span>
                      </div>
                    </div>
                    <div class="item-description fs-11 w-100 mt-1 pad-left-right">
                      {{ itemInfo.description }}
                    </div>
                  </div>
                  <div class="banner mt-3 pad-left-right" v-if="itemInfo.slides.length">
                    <swiper
                      :slides-per-view="1"
                      :space-between="0"
                      :loop="true"
                      :autoplay="{
                        delay: 3000,
                        disableOnInteraction: false,
                      }"
                      :pagination="{
                        clickable: true,
                        el: '.swiper-pagination',
                      }"
                    >
                      <swiper-slide v-for="(value, index) in itemInfo.slides" :key="index">
                        <div class="slider" @click="clickLink(value)" style="position: relative;display: flex; align-items: center; justify-content: center;">
                          <div v-if="value.direct_to_suggested_adds_on == 1 && value.itemSelected" style="position: absolute;width: 90%;height: 90%;align-items: center;display: flex;">
                            <div style="width: 100%;height: 80%;display: inline-flex;gap:2%;">
                              <div style="width: 49%;height: 100%;">
                                <img
                                  :src=value.itemSelected.image
                                  alt="error"
                                  style="height: 100%;"
                                />
                              </div>
                              <div style="width: 49%">
                                <div style="background: gray;color:white;padding-left: 10px;">
                                  <div>ADDS ON S${{ value.itemSelected.price }}</div>
                                </div>
                                <div style="background: gray;color:white;margin-top:5px;padding-left: 10px;">
                                  <div>{{ value.itemSelected.item_name }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <img
                            :src=value.file_url
                            alt="error"
                          />
                        </div>
                      </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination"></div>
                  </div>
                  <!-- <div class="break-line" style="margin-top: 20px;" v-if="itemInfo.modifier_group && itemInfo.modifier_group.length > 0"></div> -->
                  <!-- <div class="highlight-banner">
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/04/75/78/56/360_F_475785604_HDtTcxBFA0Av87F7JoFmpircCcatQ22b.jpg"
                        alt="error"
                      />
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/04/75/78/56/360_F_475785604_HDtTcxBFA0Av87F7JoFmpircCcatQ22b.jpg"
                        alt="error"
                      />
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/04/75/78/56/360_F_475785604_HDtTcxBFA0Av87F7JoFmpircCcatQ22b.jpg"
                        alt="error"
                      />
                    </div>
                    <div>
                      <img
                        src="https://t3.ftcdn.net/jpg/04/75/78/56/360_F_475785604_HDtTcxBFA0Av87F7JoFmpircCcatQ22b.jpg"
                        alt="error"
                      />
                    </div>
                  </div> -->
                  <div
                    v-for="(modifierGroup, index) in itemInfo.modifier_group"
                    :key="index"
                  >
                    <div class="accordion pt-3 pb-3 pad-left-right">
                      <div>
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div style="display: flex;align-items: center;">
                            <div class="fs-16 font-weight-bold modifierGroupName">
                              {{ modifierGroup.name }} 
                            </div>
                            <div class="d-flex me-2" v-if="modifierGroup.name && modifierGroup.name.length <= 22">
                              <div class="fs-11 mt-1 ms-1">
                                <span class="font-inter"
                                  v-if="modifierGroup.min_qty == 0 && modifierGroup.max_qty > 0" >
                                  Select up to {{ modifierGroup.max_qty }}
                                </span>
                                <span class="font-inter" 
                                  v-else-if="modifierGroup.min_qty == 1 && modifierGroup.max_qty == 1"
                                  :class="{ 'select-min': modifierGroup.min_qty > modifierGroup.total_selected }"
                                >
                                  Select 1
                                </span> 
                                <span class="font-inter" 
                                  v-else-if="modifierGroup.min_qty == modifierGroup.max_qty && modifierGroup.max_qty > 1"
                                  :class="{ 'select-min': modifierGroup.min_qty > modifierGroup.total_selected }"
                                >
                                  Select {{ modifierGroup.max_qty }} ({{ modifierGroup.total_selected }}/{{ modifierGroup.max_qty }})
                                </span>
                                <span class="font-inter" 
                                  v-else
                                  :class="{ 'select-min': modifierGroup.min_qty > modifierGroup.total_selected }"
                                >
                                  Select {{ modifierGroup.min_qty }} to {{ modifierGroup.max_qty }}
                                </span> 
                              </div>
                            </div>
                            <div v-if="modifierGroup.min_qty > 0 && modifierGroup.total_selected >= modifierGroup.min_qty" class="icon-check ms-1"></div>
                          </div>
                          <div class="d-inline-block signature-highlight modifierGroupSignature">
                            <div>
                              {{
                                modifierGroup.min_qty == 0
                                  ? "Optional"
                                  : "Compulsory"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="d-flex" v-if="modifierGroup.name && modifierGroup.name.length > 22">
                          <div class="fs-11 ">
                            <span class="font-inter"
                              v-if="modifierGroup.min_qty == 0 && modifierGroup.max_qty > 0" >
                              Select up to {{ modifierGroup.max_qty }}
                            </span>
                            <span class="font-inter" 
                              v-else-if="modifierGroup.min_qty == 1 && modifierGroup.max_qty == 1"
                              :class="{ 'select-min': modifierGroup.min_qty > modifierGroup.total_selected }"
                            >
                              Select 1
                            </span> 
                            <span class="font-inter" 
                              v-else-if="modifierGroup.min_qty == modifierGroup.max_qty && modifierGroup.max_qty > 1"
                              :class="{ 'select-min': modifierGroup.min_qty > modifierGroup.total_selected }"
                            >
                              Select {{ modifierGroup.max_qty }} ({{ modifierGroup.total_selected }}/{{ modifierGroup.max_qty }})
                            </span>
                            <span class="font-inter" 
                              v-else
                              :class="{ 'select-min': modifierGroup.min_qty > modifierGroup.total_selected }"
                            >
                              Select {{ modifierGroup.min_qty }} to {{ modifierGroup.max_qty }}
                            </span> 
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div
                          class="row option-item align-items-center"
                          v-for="(item, index2) in modifierGroup.items"
                          :key="index2"
                          style="align-items: flex-start;"
                        >
                          <div class="col-8">
                            <div v-if="modifierGroup.min_qty == 1 && modifierGroup.max_qty == 1" class="d-flex align-items-center" :class="{'modifier-not-available': item.is_not_available }" @click="selectOnlyItem(index, index2, item)">
                              <MaterialRadio
                                :id="`item_${index}`"
                                :name="`item_${index}`"
                                class="ps-0 d-flex align-items-center option-item-radio"
                                :value="true"
                                v-model="item.selected"
                                :isDisabled="true"
                              >
                              </MaterialRadio>
                              <div class="ms-1" :class="{'font-inter-and-selected': item.selected, 'font-inter-and-not-selected': !item.selected}">
                                <span>{{ item.item_name }} <span v-if="item.itemInMog">({{ getModifierItemsInModifierItem(item.itemInMog) }} <span @click="selectOnlyItem(index, index2, item)" style="color: blue;">, edit</span>)</span></span>
                                <span v-if="item.is_not_available" class="ms-2" style="color: #eb2f06;font-size: 13px;width: 100%;">NOT AVAILABLE</span>
                              </div>
                            </div>
                            <div v-else class="d-flex align-items-center" :class="{'modifier-not-available': item.is_not_available }" @click="selectMultiItem(index, index2, item)">
                              <material-checkbox
                                v-model="item.selected"
                                :checked="item.selected"
                                :id="`item_${index}_${index2}`"
                                :name="`item_${index}_${index2}`"
                                :isDisabled="item.is_not_available || (modifierGroup.max_qty == modifierGroup.total_selected) ? !modifierGroup.items_selected.includes(index2) : false"
                              >
                              </material-checkbox>
                              <div class="ms-1" :class="{'font-inter-and-selected': item.selected, 'font-inter-and-not-selected': !item.selected, 'disabled-item': (modifierGroup.max_qty == modifierGroup.total_selected) ? !modifierGroup.items_selected.includes(index2) : false}">
                                <span>{{ item.item_name }} <span v-if="item.itemInMog">({{ getModifierItemsInModifierItem(item.itemInMog) }} <span @click="selectMultiItem(index, index2, item)" style="color: blue;">, edit</span>)</span></span>
                                <span v-if="item.is_not_available" class="ms-2" style="color: #eb2f06;font-size: 13px;width: 100%;">NOT AVAILABLE</span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-4 d-flex align-items-center justify-content-end"
                            style="display: grid !important;"
                          >
                            <div v-if="!modifierGroup.is_remark && item.price > 0" style="text-align: right;" :class="{'font-inter-and-selected': item.selected, 'font-inter-and-not-selected': !item.selected}">
                              +{{ isLogined ? item.member_price_format : item.price_default_format }}
                            </div>
                            <div class="d-flex align-items-center" v-if="item.selected && item.item_max_qty > 1 && modifierGroup.max_qty > 1">
                              <i
                                class="material-icons-round opacity-10 cursor-pointer"
                                @click="
                                  removeModifierOptionGroup(index, index2)
                                "
                                >remove</i
                              >
                              <div class="item-quantity">
                                {{ item.quantity }}
                              </div>
                              <i
                                class="material-icons-round opacity-10 cursor-pointer"
                                @click="addModifierOptionGroup(index, index2)"
                                >add</i
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="break-line"></div>
                  </div>
                </div>
                <div class="add-to-cart">
                  <div class="total-item" style="width: 37% !important;">
                    <div class="d-flex align-items-center" style="width: 100%;">
                      <i
                        style="width: 30%;text-align: left;font-size: 25px;"
                        class="material-icons-round opacity-10 cursor-pointer"
                        @click="itemInfo.quantity > 1 ? removeItem() : null"
                        >remove</i
                      >
                      <div class="item-quantity" style="width: 40%;">{{ itemInfo.quantity }}</div>
                      <i
                        style="width: 30%;text-align: right;font-size: 25px;"
                        class="material-icons-round opacity-10 cursor-pointer"
                        @click="addItem()"
                        >add</i
                      >
                    </div>
                  </div>
                  <div style="width: 3%;"></div>
                  <material-button style="width: 60% !important;" class="btn-add-to-cart" :class="{ 'disable': itemInfo.disable_add_to_cart }" @click="addToCart()">
                    <div style="font-family: 'Inter', sans-serif;">Add to cart</div>
                    <!-- <div style="font-family: 'Inter', sans-serif;">
                      S$
                      {{
                        isLogined ? (itemInfo.member_price ? (itemInfo.member_price * itemInfo.quantity).toFixed(2) : "0.00") : (itemInfo.price_default ? (itemInfo.price_default * itemInfo.quantity).toFixed(2) : "0.00")
                      }}
                    </div> -->
                  </material-button>
                </div>
              </div>
            </div>
            <material-loading :active="loaderActive" />
          </div>
        </div>
        <div class="item-name-top">
          <div>{{ itemInfo.item_name }}</div>
        </div>
      </div>
    </div>
  </div>

  <ModelCustomizationForModifierItem :categoryId="categoryId" :itemId="itemId1" :itemDetail="itemDetail1" :isLogined="isLogined" :reloadModal="reloadModal1" :isVisible="showPopup" @close="showPopup = false"></ModelCustomizationForModifierItem>
</template>

<script>
import OrderService from "../services/order.service";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import $ from "jquery";
import EventBus from "vue3-eventbus";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { localStorageName } from '@/config/utils';
import ModelCustomizationForModifierItem from './ModelCustomizationForModifierItem.vue';

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Customization",
  components: {
    MaterialButton,
    MaterialCheckbox,
    MaterialLoading,
    MaterialRadio,
    Swiper,
    SwiperSlide,
    ModelCustomizationForModifierItem
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    itemId: {
      type: Number,
      required: true,
    },
    itemDetail: {
      type: Object,
      required: true,
    },
    isLogined: {
      type: Boolean,
      default: false,
    },
    reloadModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadDone: false,
      loaderActive: false,
      merchantCode: "",
      branchCode: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      itemInfo: {},
      disableBtnAddToCart: false,
      getModifierToShowSlideSelected: [],
      cartName: 'ORDER_INFO',
      itemDetail1: {},
      reloadModal1: 'reload-item-detail',
      showPopup: false,
      indexMog1: 0,
      indexMog2: 0,
      itemMog: null,
      isWorkingSelectMogItemMultiple: false,
      itemId1: 0
    };
  },
  async created() {
    this.cartName = await this.$store.dispatch('cartName');
  },
  async mounted() {
    EventBus.on("dataAfterAdded", (e) => {
      setTimeout(() => {
        if(e && e.dataAfterAdded && this.itemInfo) {
          // console.log("this.indexMog1", this.indexMog1);
          // console.log("this.indexMog2", this.indexMog2);
          // console.log("this.itemMog", this.itemMog);
          // console.log("this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2]", this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2]);
          this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog = e.dataAfterAdded;
          console.log("this.itemInfo.modifier_group", this.itemInfo.modifier_group);
          console.log("e.dataAfterAdded", e.dataAfterAdded);
          if (
            this.itemInfo.modifier_group[this.indexMog1] &&
            this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2]
          ) {
            if (!this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].is_not_available) {
              if(this.isWorkingSelectMogItemMultiple == true) {
                var member_price1 = this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price;
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price = (e.dataAfterAdded.member_price - member_price1);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price = (e.dataAfterAdded.member_price);
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price_format = "S$ "+(e.dataAfterAdded.member_price - member_price1);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price_format = this.formatPrice(e.dataAfterAdded.member_price);
                var default_price1 = this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default;
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default = (e.dataAfterAdded.price_default - default_price1);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default = (e.dataAfterAdded.price_default);
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default_format = "S$ "+(e.dataAfterAdded.price_default - default_price1);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default_format = this.formatPrice(e.dataAfterAdded.price_default);
                this.itemInfo.member_price +=
                  ((this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.member_price *
                  this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.quantity) - member_price1);
                this.itemInfo.price_default +=
                  ((this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.price_default *
                  this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.quantity) - default_price1);
                // console.log("this.itemInfo", this.itemInfo);
                this.isWorkingSelectMogItemMultiple = false;
              } else {
                // this.itemInfo.member_price +=
                //   ((this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.member_price *
                //   this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.quantity) - this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price);
                // this.itemInfo.price_default +=
                //   ((this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.price *
                //   this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.quantity) - this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price);
                var member_price2 = this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price;
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price = (e.dataAfterAdded.member_price - member_price2);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price = (e.dataAfterAdded.member_price);
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price_format = "S$ "+(e.dataAfterAdded.member_price - member_price2);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].member_price_format = this.formatPrice(e.dataAfterAdded.member_price);
                var default_price2 = this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price;
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default = (e.dataAfterAdded.price_default - default_price2);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default = (e.dataAfterAdded.price);
                // this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default_format = "S$ "+(e.dataAfterAdded.price_default - default_price2);
                this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].price_default_format = this.formatPrice(e.dataAfterAdded.price);
                this.itemInfo.member_price +=
                  ((this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.member_price *
                  this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.quantity) - member_price2);
                this.itemInfo.price_default +=
                  ((this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.price *
                  this.itemInfo.modifier_group[this.indexMog1].items[this.indexMog2].itemInMog.quantity) - default_price2);
              }
              // this.checkAvailableAddToCart();
            }
          }
        }
      }, 300);
    });
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.closeModel(true));
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
        return 'S$ ' + parseFloat(price).toFixed(2);
    },
    closeModel(isPopState = false) {
      if (!isPopState) {
        this.$emit('clear-data-item');
      }
      
      $(".btn-hide-item-detail-modal").click();
    },

    handleScroll(event) {
      var scrollTop = event.srcElement.scrollTop;
      var scrollMax = 16 + $('.item-image').height() + 16;
      
      if (scrollTop >= scrollMax) {
        $('.item-name-top').addClass('active');
      } else {
        if (scrollTop < scrollMax) {
          $('.item-name-top').removeClass('active');
        }
      }
    },

    showHideModifier(index) {
      if (this.itemInfo.modifier_group[index]) {
        if (this.itemInfo.modifier_group[index].is_show) {
          this.itemInfo.modifier_group[index].is_show = false;
        } else {
          this.itemInfo.modifier_group.forEach((item, idx) => {
            this.itemInfo.modifier_group[idx].is_show = false;
          })

          this.itemInfo.modifier_group[index].is_show = true;
        }
      }
    },

    addModifierOptionGroup(index, index2) {
      if (
        this.itemInfo.modifier_group[index] &&
        this.itemInfo.modifier_group[index].items[index2]
      ) {
        if (this.itemInfo.modifier_group[index].items[index2].quantity < this.itemInfo.modifier_group[index].items[index2].item_max_qty && this.itemInfo.modifier_group[index].total_selected < this.itemInfo.modifier_group[index].max_qty) {
          this.itemInfo.modifier_group[index].items[index2].quantity += 1;
          this.itemInfo.member_price += this.itemInfo.modifier_group[index].items[
            index2
          ].member_price;
          this.itemInfo.price_default += this.itemInfo.modifier_group[index].items[
            index2
          ].price_default;
          this.itemInfo.modifier_group[index].total_selected += 1;

          this.checkAvailableAddToCart();
        }
      }
    },

    removeModifierOptionGroup(index, index2) {
      if (
        this.itemInfo.modifier_group[index] &&
        this.itemInfo.modifier_group[index].items[index2]
      ) {
        if (this.itemInfo.modifier_group[index].items[index2].quantity > 1) {
          this.itemInfo.modifier_group[index].items[index2].quantity -= 1;
          this.itemInfo.member_price -= this.itemInfo.modifier_group[index].items[
            index2
          ].member_price;
          this.itemInfo.price_default -= this.itemInfo.modifier_group[index].items[
            index2
          ].price_default;
          this.itemInfo.modifier_group[index].total_selected -= 1;

          this.checkAvailableAddToCart();
        }
      }
    },

    selectOnlyItem(index, index2, item) {
      if (
        this.itemInfo.modifier_group[index] &&
        this.itemInfo.modifier_group[index].items[index2]
      ) {
        if (!this.itemInfo.modifier_group[index].items[index2].is_not_available) {
          this.indexMog1 = index;
          this.indexMog2 = index2;
          this.itemMog = item;

          if (!this.itemInfo.modifier_group[index].items[index2].selected) {
            this.itemInfo.member_price +=
              this.itemInfo.modifier_group[index].items[index2].member_price *
              this.itemInfo.modifier_group[index].items[index2].quantity;
            this.itemInfo.price_default +=
              this.itemInfo.modifier_group[index].items[index2].price *
              this.itemInfo.modifier_group[index].items[index2].quantity;
          }

          this.itemInfo.modifier_group[index].items.forEach((item, idx) => {
            if (idx != index2) {
              if (item.selected) {
                this.itemInfo.member_price -= item.member_price * item.quantity;
                this.itemInfo.price_default -= item.price * item.quantity;
                this.itemInfo.modifier_group[index].items[idx].quantity = 1;
              }
              item.selected = false;
            } else {
              this.gotoOrderDetail(this.categoryId, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item);
              item.selected = true;
            }
          });
        }
      }
    },
    updateSelectedItems(array1, array2) {
      // Get all selected IDs from array1 based on group.id
      const selectedIds = [];
      // Iterate through all groups in array1
      array1.forEach(group => {
        group.items.forEach(item => {
          if (item.selected) {
            selectedIds.push({ id: item.id, groupId: group.id });
          }
        });
        // Update total_selected and items_selected for array2
        const array2Group = array2.find(g => g.id === group.id);
        if (array2Group) {
          array2Group.total_selected = group.total_selected;
          array2Group.items_selected = group.items_selected;
        }
      });
      // Update selected and other information for array2
      array2.forEach(group => {
        group.items.forEach(item => {
          // const selectedItem = group.items.find(selected => 
          //   selected.id === item.id && selected.groupId === group.id
          // );
          if (selectedIds.some(selected => selected.id === item.id && selected.groupId === group.id)) {
            item.selected = true;
            // Get information from array1
            const sourceItem = array1
              .find(g => g.id === group.id)
              .items.find(srcItem => srcItem.id === item.id);
            if (sourceItem) {
              item.price = sourceItem.price;
              item.price_format = sourceItem.price_format;
              item.price_default = sourceItem.price_default;
              item.price_default_format = sourceItem.price_default_format;
              item.member_price = sourceItem.member_price;
              item.member_price_format = sourceItem.member_price_format;
              item.item_max_qty = sourceItem.item_max_qty;
              item.quantity = sourceItem.quantity;
              item.is_not_available = sourceItem.is_not_available;
              item.stock_balance = sourceItem.stock_balance;
              item.stock_enable_balance = sourceItem.stock_enable_balance;
            }
          } else {
            item.selected = false;
          }
        });
      });
      return array2;// Return the updated array
    },
    gotoOrderDetail(categoryId, id, stock_balance, is_not_available, stock_enable_balance, item) {
      // console.log(stock_balance, is_not_available, stock_enable_balance, item)
      // if(this.checkAvailableItem(item) && item.isHaveModifierOptionGroups > 0) {
      if(this.checkAvailableItem(item)) {
        this.categoryIdSelect = categoryId
        this.itemIdSelect = id;
        // this.showLoader();
        let dataForm = {
          menuset_id: this.branch.menuset_id ?? 0,
          category_id: categoryId,
          item_id: id,
          branch_code: this.branch.branch_code ?? "",
        };
        OrderService.getItemDetail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              if(response.data.data.modifier_group && response.data.data.modifier_group.length > 0) {
                this.itemDetail1 = response.data.data;
                this.itemId1 = this.itemDetail1.id;
                this.reloadModal1 = Math.random(32).toString();
                if(item && item.itemInMog && item.itemInMog.modifier_group) {
                  // console.log("item.itemInMog", item.itemInMog);
                  this.itemDetail1.category_id = item.itemInMog.category_id;
                  this.itemDetail1.description = item.itemInMog.description;
                  this.itemDetail1.disable_add_to_cart = item.itemInMog.disable_add_to_cart;
                  this.itemDetail1.is_not_available = item.itemInMog.is_not_available;
                  this.itemDetail1.member_price = item.itemInMog.member_price;
                  this.itemDetail1.member_price_no_modifier_option = item.itemInMog.member_price_no_modifier_option;
                  this.itemDetail1.price = item.itemInMog.price;
                  this.itemDetail1.price_default = item.itemInMog.price_default;
                  this.itemDetail1.price_default_no_modifier_option = item.itemInMog.price_default_no_modifier_option;
                  this.itemDetail1.quantity = item.itemInMog.quantity;
                  this.itemDetail1.stock_balance = item.itemInMog.stock_balance;
                  this.itemDetail1.stock_enable_balance = item.itemInMog.stock_enable_balance;
                  console.log("this.updateSelectedItems(item.itemInMog.modifier_group, response.data.data.modifier_group)", this.updateSelectedItems(item.itemInMog.modifier_group, response.data.data.modifier_group));
                  // console.log("item", item.itemInMog.modifier_group);
                  // console.log("item1", response.data.data.modifier_group);
                }
                this.showPopup = true;
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            // this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            // this.hideLoader();
          }
        );
      }
    },
    selectMultiItem(index, index2, item) {
      if (
        this.itemInfo.modifier_group[index] &&
        this.itemInfo.modifier_group[index].items[index2]
      ) {
        if (!this.itemInfo.modifier_group[index].items[index2].is_not_available) {
          if (!this.itemInfo.modifier_group[index].items[index2].selected && this.itemInfo.modifier_group[index].total_selected >= this.itemInfo.modifier_group[index].max_qty) {
            return;
          }
          this.indexMog1 = index;
          this.indexMog2 = index2;
          this.itemMog = item;

          this.itemInfo.modifier_group[index].items[index2].selected = !this.itemInfo.modifier_group[index].items[index2].selected;

          if (this.itemInfo.modifier_group[index].items[index2].selected) {
            this.itemInfo.modifier_group[index].total_selected += 1;
            this.itemInfo.member_price +=
              this.itemInfo.modifier_group[index].items[index2].member_price *
              this.itemInfo.modifier_group[index].items[index2].quantity;
            this.itemInfo.price_default +=
              this.itemInfo.modifier_group[index].items[index2].price_default *
              this.itemInfo.modifier_group[index].items[index2].quantity;

            this.itemInfo.modifier_group[index].items_selected.push(index2);
            this.isWorkingSelectMogItemMultiple = true;
            this.gotoOrderDetail(this.categoryId, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item);
          } else {
            this.itemInfo.modifier_group[index].total_selected -= this.itemInfo.modifier_group[index].items[index2].quantity;
            this.itemInfo.member_price -=
              this.itemInfo.modifier_group[index].items[index2].member_price *
              this.itemInfo.modifier_group[index].items[index2].quantity;
            this.itemInfo.price_default -=
              this.itemInfo.modifier_group[index].items[index2].price_default *
              this.itemInfo.modifier_group[index].items[index2].quantity;
            this.itemInfo.modifier_group[index].items[index2].quantity = 1;

            this.itemInfo.modifier_group[index].items_selected.splice(this.itemInfo.modifier_group[index].items_selected.indexOf(index2));
          }

          this.checkAvailableAddToCart();
        }
      }
    },

    addItem() {
      this.itemInfo.quantity += 1;
    },

    removeItem() {
      this.itemInfo.quantity -= 1;
    },

    addToCart() {
      if (this.itemInfo.disable_add_to_cart) {
        let mess = "Please make a selection for "

        this.itemInfo.modifier_group.forEach((item) => {
          if (item.min_qty > item.total_selected) {
            mess += item.name;
            return;
          }
        });

        alert(mess);
      } else {
        let checkOrderSuccess = true;
        var modifier_group_full = JSON.parse(JSON.stringify(this.itemInfo.modifier_group));

        this.itemInfo.modifier_group_full = modifier_group_full;

        this.itemInfo.modifier_group.forEach((item) => {
          if (item.total_selected < item.min_qty) {
            checkOrderSuccess = false;
            return;
          }
        });

        if (!checkOrderSuccess) {
          return;
        }

        this.itemInfo.modifier_group = this.itemInfo.modifier_group.filter(
          (modifierGroup, index) => {
            if (modifierGroup.total_selected == 0) {
              return false;
            } else {
              this.itemInfo.modifier_group[
                index
              ].items = modifierGroup.items.filter((item) => {
                if (!item.selected) {
                  return false;
                }

                return true;
              });
            }

            return true;
          }
        );

        this.itemInfo.modifier_group_full = this.itemInfo.modifier_group_full.filter(
          (modifierGroup, index) => {
            if (modifierGroup.total_selected == 0) {
              return false;
            } else {
              this.itemInfo.modifier_group_full[
                index
              ].items = modifierGroup.items.filter((item) => {
                if (item.selected) {
                  return false;
                }

                return true;
              });
            }

            return true;
          }
        );

        let orderInfo =
          JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
        const objectInArrayIndex = orderInfo.findIndex(item => {
            if (this.compareObjects(item, this.itemInfo)) {
                item.quantity += this.itemInfo.quantity;
                return true;
            }
            return false;
        });
        if (objectInArrayIndex !== -1) {
          // console.log(`Object match at index: ${objectInArrayIndex}`);
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
        } else {
          orderInfo.push(this.itemInfo);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
        }
        EventBus.emit("AddToCartSuccess");
        EventBus.emit("AddToCartThenReloadCategorySuccess", {time: this.reloadModal});
        this.closeModel();
      }
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    clickLink(slide) {
      if(slide && slide.destination_link != "") {
        window.open(slide.destination_link);
      } else if(slide && slide.direct_to_menu_item != "" && !slide.direct_to_suggested_adds_on) {
        this.showLoader();
        let dataForm = {
          menuset_id: this.branch.menuset_id ?? 0,
          category_id: this.categoryId ?? 0,
          item_id: slide.direct_to_menu_item ?? 0,
          branch_code: this.$store.state.branchCode,
        };

        OrderService.getItemDetail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.isLoadDone = true;
              this.itemInfo = response.data.data;
              this.itemInfo.price_default = parseFloat(this.itemInfo.price_default);

              if (this.itemInfo.member_price_no_modifier_option > 0) {
                this.itemInfo.member_price = parseFloat(this.itemInfo.member_price);
              } else {
                this.itemInfo.member_price = parseFloat(this.itemInfo.member_price) + parseFloat(this.itemInfo.price_default_no_modifier_option);
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
          }
        );
      }
    },

    getModifierToShowSlide(modifierGroups) {
      // Iterate through modifier groups
      for (const group of modifierGroups) {
        // Find item with price > 0
        const itemWithPrice = group.items.find(subItem => subItem.price > 0);
        if (itemWithPrice) {
          return itemWithPrice;
        }
      }

      // If no item with price > 0 return the first item from the first group
      const firstItem = modifierGroups[0].items[0];
      
      return firstItem;
    },

    calculateTotalStockBalanceForFoundItems(itemInfo) {
      let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      let totalStockBalance = 0;
      for (let item of orderInfo) {
        if (item.id === itemInfo.id) {
          totalStockBalance += item.quantity;
        }
      }
      return totalStockBalance;
    },

    calculateTotalStockBalanceForFoundItemsModifier(itemInfo) {
      let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      let totalStockBalance = 0;
      for (let i = 0; i < orderInfo.length; i++) {
          if (orderInfo[i] && orderInfo[i].modifier_group) {
              for (let j = 0; j < orderInfo[i].modifier_group.length; j++) {
                  if (orderInfo[i].modifier_group[j].items) {
                      for (let k = 0; k < orderInfo[i].modifier_group[j].items.length; k++) {
                          if (orderInfo[i].modifier_group[j].items[k].id === itemInfo.id) {
                              totalStockBalance += orderInfo[i].modifier_group[j].items[k].quantity;
                              // return;
                          }
                      }
                  }
              }
          }
      }
      return totalStockBalance;
    },

    checkAvailableItem() {
      const item = this.itemInfo;
      var stockBalance = this.itemInfo.stock_balance - this.calculateTotalStockBalanceForFoundItems(this.itemInfo);

      if(item.stock_enable_balance) { // countdown = true
          if(item.stock_balance > 0) {
              if(stockBalance > 0) {
                  return true;
              } else {
                  return false;
              }
          } else {
              return false;
          }
      } else { // countdown = false
          if(item.is_not_available == 0) {
            return true;
          } else {
              return false;
          }
      }
    },

    checkAvailableModifierItem(item) {
      var stockBalance = item.stock_balance - this.calculateTotalStockBalanceForFoundItemsModifier(item);
      if(item.stock_enable_balance) { // countdown = true
          if(item.stock_balance > 0) {
              if(stockBalance > 0) {
                  return false;
              } else {
                  return true;
              }
          } else {
              return true;
          }
      } else { // countdown = false
          if(item.is_not_available == 0) {
            return false;
          } else {
              return true;
          }
      }
    },

    checkAvailableAddToCart() {
      let check = false;

      this.itemInfo.modifier_group.forEach((item) => {
        if (item.min_qty > item.total_selected) {
          check = true;
          return;
        }
      });

      this.itemInfo.disable_add_to_cart = check;
    },
    compareObjects(obj1, obj2) {
      // Check main item ID
      if (obj1.id !== obj2.id) {
          console.log("IDs do not match:", obj1.id, obj2.id);
          return false;
      }
      
      if (this.isLogined) {
        if (obj1.member_price != obj2.member_price) {
          console.log("Price do not match:", obj1.id, obj2.id);
          return false;
        }
      } else {
        if (obj1.price_default != obj2.price_default) {
          console.log("Price do not match:", obj1.id, obj2.id);
          return false;
        }
      }

      // Check modifier group items
      if (obj1.modifier_group.length !== obj2.modifier_group.length) {
          console.log("The length of the modifier group does not match:", obj1.modifier_group.length, obj2.modifier_group.length);
          return false;
      }

      for (let i = 0; i < obj1.modifier_group.length; i++) {
          const group1 = obj1.modifier_group[i];
          const group2 = obj2.modifier_group[i];
          if (group1.items.length !== group2.items.length) {
              console.log(`Length of modifier group ${i + 1} not match:`, group1.items.length, group2.items.length);
              return false;
          }

          for (let j = 0; j < group1.items.length; j++) {
              if (group1.items[j].id !== group2.items[j].id || group1.items[j].quantity !== group2.items[j].quantity) {
                  console.log(`ID of the item in the modifier group ${i + 1}, item ${j + 1} not match:`, group1.items[j].id, group2.items[j].id);
                  return false;
              }

              // Add quantity to item in modifier
              // group1.items[j].quantity += group2.items[j].quantity;
          }
      }

      // All checks passed
      return true;
    },
    clearDataItem() {
      this.itemIdSelect = 0;
      // this.itemDetail = {};
    },
    getModifierItemsInModifierItem(item) {
        var name = [];
        item.modifier_group.forEach((group) => {
            group.items.forEach((itemModifier) => {
                name.push(itemModifier.item_name);
            });
        });
        return name && name.length > 0 ? name.join(', ') : "";
    }
  },
  watch: {
    reloadModal() {
      if (this.itemId) {
        this.itemInfo = this.itemDetail

        if (this.itemInfo.slides) {
          var usedItems = [];
          this.itemInfo.slides.forEach(slide => {
            if (slide.direct_to_suggested_adds_on === 1) {
              this.itemInfo.modifier_group.forEach(modifierGroup => {
                const selectedItem = modifierGroup.items.find(item => item.price > 0 && !usedItems.includes(item.id));
                if (selectedItem) {
                  usedItems.push(selectedItem.id)
                  slide.itemSelected = selectedItem;
                } else {
                  const selectedItemPriceIsZero = modifierGroup.items.find(item => item.price == 0 && !usedItems.includes(item.id));
                  if (selectedItemPriceIsZero) {
                    usedItems.push(selectedItemPriceIsZero.id)
                    slide.itemSelected = selectedItemPriceIsZero;
                  }
                }
              });
            }
          });
        }

        this.itemInfo.price_default = parseFloat(this.itemInfo.price_default);
        
        if (this.itemInfo.member_price_no_modifier_option > 0) {
          this.itemInfo.member_price = parseFloat(this.itemInfo.member_price);
        } else {
          this.itemInfo.member_price = parseFloat(this.itemInfo.member_price) + parseFloat(this.itemInfo.price_default_no_modifier_option);
        }

        this.isLoadDone = true;
      } else {
        this.isLoadDone = false;
      }
    },
  },
};
</script>

<style scoped>
/* Css model */
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

/* Full Screen */
.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal.modal-full .modal-content .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

.modal.modal-full.fade .modal-dialog {
	visibility: hidden;
  opacity:0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s 0.5s;
}

.modal.modal-full.fade.show .modal-dialog {
  visibility: visible;
  -webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
  transition: opacity 0.5s ease, visibility 0s;
}

.modal.modal-full.fade .modal-dialog {
  bottom: 0;
  -webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	opacity: 0;
	-webkit-transition: all 0.15s;
	transition: all 0.15s;
}

.btn-close-modal {
  position: absolute;
  background-color: #F8F8F8;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  z-index: 999999
}

.btn-close-modal div {
  font-size: 32px !important;
  font-weight: bold;
  color: var(--color) !important;
  padding-right: 0 !important;
  margin-bottom: 4.5px;
}

/* CSS item detail */
.page-header {
  position: relative;
  background: #ffffff;
  height: unset !important;
  overflow-y: unset !important;
  padding-bottom: 0 !important;
}

.page-header .container {
  padding: 0 !important;
}

.page-header .container .content {
  /* margin-top: 65px; */
  margin-bottom: 73px;
}

/* .item-img-container,
.accordion {
  padding-left: 24px;
  padding-right: 24px;
} */

.item-img-container {
  position: relative;
  max-height: 590px !important;
}

.item-img-container .btn-back {
  position: absolute;
  top: 5px;
  left: 0px;
  cursor: pointer;
}

.item-img-container img {
  width: 100%;
  height: auto;
  max-height: 400px;
}

.item-img-container .item-name {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

.signature-highlight {
  display: flex !important;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px;
  background-color: var(--color);
  height: 15px;
}

.signature-highlight div {
  font-size: 9px !important;
  line-height: 13px;
  font-weight: bold;
}

.signature-highlight i {
  font-size: 11px;
  margin-top: 3px;
}

.highlight-banner {
  display: flex;
  width: 100% !important;
  overflow-x: auto;
  padding: 12px 24px;
  background-color: #fcf1f9;
}

.highlight-banner img {
  min-width: 100%;
  height: 100px;
}

.highlight-banner div:not(:first-child) {
  padding-left: 15px;
}

.accordion > div:first-child {
  padding-bottom: 5px;
  /* border-bottom: 2px solid #f1f1f1; */
}

.accordion > div {
  cursor: pointer;
}

.accordion .option-item {
  font-size: 13px;
  font-weight: bold;
  align-items: center;
  margin-top: 10px;
}

.accordion .option-item > div:first-child {
  padding-right: 0;
}

.accordion .option-item i,
.total-item i {
  font-size: 13px;
  font-weight: bold;
  /* padding: 2px; */
}

.accordion .option-item .item-quantity,
.total-item .item-quantity {
  width: 27px;
  text-align: center;
  border-radius: 3px;
  background: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
  padding: 4px 2px;
  margin: 0 3px;
  border: none;
}

.total-item .item-quantity {
  font-size: 18px;
  width: 35px;
  font-weight: bold;
  margin: 0 5px;
  font-family: 'Inter', sans-serif;
}

.total-item i {
  font-size: 18px;
}

.add-to-cart {
  position: fixed;
  left: 0;
  z-index: 99999;
  width: 100%;
  border-top: 2px solid #f1f1f1;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 1em;
  padding-right: 1em;
}

.btn-add-to-cart {
  width: fit-content !important;
  text-transform: unset !important;
  background-color: transparent !important;
  border-color: var(--color) !important;
  box-shadow: unset !important;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 10px 50px;
  border-radius: 10px;
  border: 1px solid var(--color) !important;
}

.btn-add-to-cart.disable {
  background: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
  color: gray !important;
}

.break-line {
  width: auto;
  height: 8px;
  background-color: #f1f1f1;
  margin-left: 1em;
  margin-right: 1em;
}

.fs-18 {
  font-size: 16px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.select-min {
  font-weight: 500;
  color: #eb2f06 !important;
}

.disabled-item {
  opacity: 0.5;
}

.item-img-container .item-description {
  font-size: 13.5px !important;
  color: #666666 !important;
  font-family: 'Inter', sans-serif;
}

.item-img-container .txt-from {
  font-size: 11px !important;
  color: gray !important;
  font-family: 'Inter', sans-serif;
}

.modifierGroupName {
  font-family: 'Inter', sans-serif;
}

.modifierGroupSignature {
  font-family: 'Inter', sans-serif;
}

.font-inter {
  font-family: 'Inter', sans-serif;
  color: #666666;
}

.font-inter-and-selected {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  color: #666666;
}
.font-inter-and-not-selected {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  color: #666666 !important;
  /* font-weight: '400' */
}
.item-image {
  height: 300px;
  width: auto;
  overflow: hidden;
  background-size: cover;
  background-position: center bottom;
  transition: transform 3s ease;
  transform-origin: center;
}
.icon-check {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('~@/assets/img/my-cart/icon-check-green.svg');
  width: 16px;
  height: 16px;
}
.item-name-top {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  z-index: 99999;
  background: #ffffff;
  opacity: 0;
  padding-left: 50px;
  padding-right: 30px;
  box-shadow: 0 2px 6px 0 rgba(28,28,28,.1);
}
.item-name-top.active {
  opacity: 1;
  transition: opacity .2s;
}
.item-name-top > div {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pad-left-right {
  padding-left: 1em; padding-right: 1em;
}
.modifier-not-available {
  opacity: 0.5 !important;
}
.modifier-not-available > div > span:first-child {
  text-decoration: line-through !important;
}
.image-container {
  width: 100%;
  height: auto;
  max-height: 400px;
  overflow: hidden;
  position: relative;
}
.food-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoomOut 1.5s ease-out forwards;
}
@keyframes zoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 480px) {
  .image-container {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
  }
}
</style>
