import axios from "axios";
import store from "@/store";
import { localStorageName } from '@/config/utils';

const checkExpiryUrl = async (to, from, next) => {
  const { meta, params, name } = to;
  const isActive = meta && meta.active !== undefined ? meta.active : true;

  if (isActive) {
    try {
      if (params.type) {
        if (params.type.toString().toUpperCase() == 'QR') {
          let canNext = false;

          await axios.post(process.env.VUE_APP_BASE_URL + '/check-expiry-url', params,
            {
              headers: {
                "MerchantCode": params.merchant_code.toLowerCase()
              }
            }
          )
          .then(
            (response) => {
              if (response.data.result.isSuccess) {
                let data = response.data.data;

                if (!data.accept_payment_on_moo) {
                  localStorage.removeItem('SELECTED_REWARD_' + params.merchant_code + '_' + params.branch_code);
                }

                localStorage.removeItem('table_' + params.merchant_code.toLowerCase() + '_' + params.branch_code.toLowerCase() + '_' + params.table_code.toLowerCase());
                localStorage.setItem('table_' + params.merchant_code.toLowerCase() + '_' + params.branch_code.toLowerCase() + '_' + params.table_code.toLowerCase(), JSON.stringify(data));
                canNext = true;
              } else {
                if(name && name == "PaymentSuccess") {
                  canNext = true;
                }
              }
            },
            (error) => {
              console.log("error", error);
              store.dispatch("setError", error.response.data.result)

              if (error.response.status == 419) {
                if(name && name == "PaymentSuccess") {
                  next(true);
                } else {
                  next({ name: 'PageExpired' });
                }
              }
            }
          )

          if (canNext) {
            next(canNext);
          } else {
            next({ name: 'Error404' });
          }
        } else {
          next(false);
        }
      } else {
        if(name && name == "Cover") {
          var branch = JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), ''))

          if (branch && !branch.show_landing_page_on_ordering_online) {
            next({ name: 'Order' });
          } else {
            next(true);
          }
        }

        next(true);
      }
    } catch (error) {
      console.log(error);
      next(false);
    }
  }
};

export default checkExpiryUrl;
